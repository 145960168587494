<template ref="footer">
  <div class="footer relative">
    <vs-navbar
      class="vx-navbar navbar-custom navbar-skelton fixed bottom-0 flex"
      :color="navbarColorLocal"
      :class="textColor">

      <!-- SM - OPEN SIDEBAR BUTTON -->
      <feather-icon
        class="useral-footer-menu-button"
        icon="MenuIcon"
        @click.stop="showSidebar"/>

      <vs-spacer/>

      <!--      <feather-icon-->
      <!--        class="useral-footer-menu-button"-->
      <!--        icon="HeartIcon"-->
      <!--        @click.stop="showSidebar"/>-->

      <!--      <vs-spacer/>-->

      <router-link :to="{name: 'saleCalendar'}">
        <custom-icon class="useral-footer-menu-button"
                     icon="CALENDER"
                     color-code="#000"
                     height="25px"
                     width="25px"/>
      </router-link>

      <vs-spacer/>

      <!--      <feather-icon class="useral-footer-menu-button"-->
      <!--                    icon="SearchIcon"-->
      <!--                    @click.stop="showSidebar"/>-->

      <!--      <vs-spacer/>-->

      <router-link :to="{name: 'home'}">
        <feather-icon class="useral-footer-menu-button"
                      icon="HomeIcon"/>
      </router-link>

      <vs-spacer/>

      <router-link :to="{name: 'Profile'}">
        <custom-icon class="useral-footer-menu-button"
                     icon="USER"
                     color-code="#000"
                     height="25px"
                     width="25px"/>
      </router-link>
    </vs-navbar>
  </div>
</template>

<script>
  import CustomIcon from "../../../components/customIcon/customIcon";

  export default {
    name: 'TheMobileFooter',
    components: {CustomIcon},
    props: {
      navbarColor: {
        type: String,
        default: '#fff'
      }
    },
    computed: {
      navbarColorLocal() {
        return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
      },
      verticalNavMenuWidth() {
        return this.$store.state.verticalNavMenuWidth
      },
      textColor() {
        return {
          'text-white':
            (this.navbarColor !== '#10163a' &&
              this.$store.state.theme === 'dark') ||
            (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')
        }
      },
      windowWidth() {
        return this.$store.state.windowWidth
      },

      // NAVBAR STYLE
      classObj() {
        if (this.verticalNavMenuWidth === 'default') return 'navbar-default'
        else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
        else if (this.verticalNavMenuWidth) return 'navbar-full'
      }
    },
    methods: {
      showSidebar() {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    background: transparent !important;

    .vx-navbar {
      height: 50px;
      border-top: 1px solid #03092d;
    }

    @media print {
      display: none;
    }
  }
</style>
